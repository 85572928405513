import { useWebSocket } from "../services/ws.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import Cookies from 'js-cookie';
import {
    resetReminder as resetReminderAPI
} from "api/auth.js";
import { bonusStore } from "store/bonus.js";
import eventBus from "plugins/event.js";

export const useBalanceSocketInit = () => {
    const useAuthStore = authStore();
    const useBonusStore = bonusStore();

    // const router = useRouter();

    const username = computed(() => {
        return useAuthStore.username;
    });

    const { socket } = useWebSocket();

    if (!socket) {
        location.reload();
    }

    socket.on('connect', () => {
        console.log('Connected with socket id:', socket.id);
        socket.emit("subscribeToPrivateChannel", "user."+(username.value ? username.value : Cookies.get("username")))
    })

    const key = import.meta.env.VITE_ENV == "production" ? "production:customer_action" : "development:customer_action"

    socket.on(key, async function (data) {
        const response = JSON.parse(data);
        if(response.action == 'check-auth') {
            useAuthStore.getUser(true);
        } else if(response.action == 'email_verified') {
            location.reload();
        } else if(response.action == 'login') {
            eventBus.emit("open:session_expired");
        } else if (response.action == 'logout_reminder') {
            resetReminderAPI();
            eventBus.emit("open:reminder");
        } else if (response.action == 'free_gc_topup') {
            useBonusStore.claimFreeGC()
        } else if (response.action == 'silver_winnings') {
            useAuthStore.setSilverRedeemable(response.balance);
        } else {
            useAuthStore.updateWalletBalance(response);
        }
    });

    const emitGameOpen = () => {
        socket.emit("game:open", "user."+(username.value ? username.value : Cookies.get("username")));
    };

    const emitGameClose = () => {
        socket.emit("game:close", "user."+(username.value ? username.value : Cookies.get("username")));
    };

    const destroySocket = () => {
        socket.off("customer_action")
    };

    return {
        socket,
        emitGameOpen,
        emitGameClose,
        destroySocket
    };
};

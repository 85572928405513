<template>

     <InertiaHead title="Terms of Service"></InertiaHead>
     <div class="home-wrapper mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative">
               <div class="text-center">
                    <div class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2">
                         Terms of Service
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28 px-4 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-64">
                         <div v-if="!loading" class="pb-2" v-html="content"></div>
                         <loading-small v-else :isLoading="loading" />

                         <!-- <div class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-6 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-48 shadow-xl">
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moshy Gaming LLC provides a social casino platform through <a href="https://moozi.com" class="text-primal-blue" target="_blank" rel="noopener noreferrer">https://moozi.com</a> and any associated websites and mobile versions ("Moozi", "us", "our", "ours", etc.), where users can acquire virtual coins ("Gold Coins, or “GC") for engaging in games with the opportunity to win additional GC. Periodically, users may also have the chance to earn entries to promotions (referred to as SCs coins, or "<span class="font-bold">SC</span>") offering cash prizes ("<span class="font-bold">Prizes</span>"). The utilization of SC is regulated by our SC Promotional Rules, accessible via the provided link. These Terms of Service establish the legal agreement between you, the user ("<span class="font-bold">you</span>", "<span class="font-bold">your</span>", "<span class="font-bold">yours</span>", etc.), and us concerning the services offered through Moozi ("<span class="font-bold">Services</span>"), inclusive of all textual content, images, graphics, photographs, audio, video, buttons, icons, animations, data, messages, software, and other content, information, or materials available on Moozi ("<span class="font-bold">Materials</span>").
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   By accessing Moozi or utilizing the Services, you acknowledge and consent to our website policies, including these Terms of Service. Furthermore, you certify to us that (a) you are at least eighteen (18) years old or the age of majority in your jurisdiction, (b) you possess the legal capacity to enter into and abide by these Terms of Service, (c) you are utilizing the Services voluntarily and for personal enjoyment, and (d) you will provide accurate and complete information to us and promptly update such information as needed to ensure accuracy and completeness.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We reserve the unilateral right to amend the Terms of Service at any time, with all modifications or changes becoming immediately effective upon posting. The updated version supersedes any prior iterations upon publication. It is <span class="font-bold">YOUR</span> responsibility to regularly revisit this page, utilizing the browser's "Refresh" button and verify the ‘Last Updated’ date. Failure to review these updated Terms of Service amounts to a waiver of your right to review.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We are not a gambling service, do not engage in illegal betting, and do not endorse or promote illegal gambling activities. We offer entertaining online social casino games and promotions compliant with state and federal gambling laws. Users are urged to exit Moozi immediately if seeking information on illegal activities and should refrain from using the Services if such use would breach local laws. You are solely responsible for adhering to applicable laws, and you assume all risks associated with using the Services. Nothing on Moozi constitutes legal advice, and we disclaim any liability for users engaging in illegal gambling. The Services are strictly for entertainment purposes, and we do not guarantee their legality in your jurisdiction.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">I.</span><span class="underline underline-offset-2">Accounts</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Registration
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   All individuals are eligible to register for a singular account on Moozi provided they fulfil and comply with the conditions outlined herein. To purchase GC on Moozi, a valid payment method must be used. For Prizes redeemal as per our SC Promotional Rules, current and accurate banking or wallet details, along with any requisite information and documentation, must be submitted.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Accuracy
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Failure to provide requisite information, provision of false, misleading, inaccurate, incomplete, outdated, or otherwise erroneous information to us, neglecting to promptly update such information for its accuracy and completeness, or if there exists reasonable suspicion of a violation of this provision by us or our authorized agents, may result in the suspension or termination of your account and/or legal ramifications. Registration acceptance is contingent upon our sole discretion. While we reserve the right to request additional information to authenticate your identity and provided details, you acknowledge and agree that no user is sponsored by us in any way.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>Prohibition of Account Sharing
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You are strictly prohibited from utilizing, attempting to access, or soliciting login credentials for any third party accounts. Under no circumstances should you permit any third party to access your account, nor should you disclose your login credentials to any third party. We bear no responsibility for any losses incurred because of unauthorized access to your account, whether with or without your knowledge. You are solely accountable for any losses suffered by us or any third party arising from the unauthorized disclosure of account credentials.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">d)</span>Account Termination by User
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You reserve the right to terminate your account by selecting the “Delete Account” option within your account settings. It is strictly prohibited to assign, transfer, sell, or share your Moozi membership. Any such actions will render both you and any unauthorized user jointly and severally liable for any ensuing fees.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">e)</span>Account Termination by Moozi
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We retain the authority to suspend or delete your account or revoke any licenses granted herein, at our discretion and at any time, without providing a reason. We reserve the right to terminate your membership at our discretion, with you being accountable for any outstanding charges on your account at the time of termination. Please note that we are not obligated to retain terminated account information, which may be permanently deleted at our discretion.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">II.</span><span class="underline underline-offset-2">Grant of Entitlements</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You acknowledge that our provision to you is limited to access to Moozi and utilization of the Services as they are periodically presented. You bear full responsibility for any fees incurred in accessing Moozi or utilizing the Services. All users are permitted to access designated public sections of Moozi and utilize the Services and accompanying Materials therein, free of charge. We afford all users a restricted, non-exclusive, revocable, and non-transferable personal license to access and employ solely those Materials available in the free sections of Moozi for private, non-commercial purposes on a single computing device or mobile device. This complimentary license does not encompass access to or usage of paid sections of Moozi or the Materials housed therein. Additionally, we extend to you a restricted, non-exclusive, revocable, and non-transferable personal license to access Moozi, utilize the Services, and engage with the Materials within, subject to your procurement of certain paid features and enhancements. This paid license is designated for private, non-commercial purposes on a single computing device or mobile device. We retain the prerogative to restrict the volume of Materials you can access or Services available to you. Your entitlement to access Moozi, use the Services, and engage with the Materials does not denote a transfer of ownership. You are prohibited from duplicating or disseminating any Materials, and you must take measures to prevent unauthorized individuals from accessing, utilizing, or duplicating the Materials.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">III.</span><span class="underline underline-offset-2">Acceptable Use Policy</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Prohibited Uses for All Users
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You assent that you will solely utilize Moozi, the Services, and the Materials for purposes explicitly permitted and envisioned by these Terms of Service. You may not employ Moozi, the Services, or the Materials for any other objectives without our explicit prior written authorization. Absent our express prior written consent, you shall not utilize the Services for any aim other than as offered by us, encompassing utilizing the Services in any manner contravening these Terms of Service or infringing upon any pertinent law, regulation, or treaty of any applicable governmental body, including:
                              </p>
                              <br>
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Laws proscribing illegal gambling;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Statutes safeguarding intellectual property rights such as patents, copyrights, trademarks, trade secrets, and any other intellectual property right, encompassing creating, acquiring, disseminating, or otherwise accessing illegal duplicates of copyrighted, trademarked, or patented content, deleting intellectual property rights indications and notices;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Statutes against indecent, lascivious, defamatory, or libelous speech;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Statutes safeguarding confidentiality, privacy rights, publicity rights, or data protection;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Neglect to adhere to orders, verdicts, or mandates from courts of competent jurisdiction;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Impersonate another person or entity, whether real or fictitious; falsely allege an affiliation with any person or entity; access the accounts of others without authorization; misrepresent the source, identity, or contents of the Materials;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Engage in platform manipulation, encompassing employing bots or other deceitful means to manipulate the games, or (artificially inflate traffic to or falsely generate engagements with your account, including your Link (as defined below) and hyperlinks to third-party websites;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Circumvent, incapacitate, impair, or otherwise meddle with the operations of Moozi, any user’s enjoyment of Moozi, or our security-related features or features that preclude, restrict, or limit the use or replication of the Services that enforce constraints on the use of Moozi or the Materials, by any means, encompassing posting, linking to, uploading, or otherwise disseminating viruses, adware, spyware, malware, logic bombs, Trojan horses, worms, harmful components, corrupted data, or other malicious code, file, or program designed to interrupt, destroy, limit, or monitor the functionality of any computer software or hardware or any telecommunications equipment;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Reverse engineer or otherwise ascertain the source code of Moozi or any part thereof, except and only if that activity is expressly permitted by applicable law notwithstanding this limitation;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Access or utilize any automated process (such as a robot, spider, scraper, or similar) to access Moozi in contravention of our robot exclusion headers or to scrape all or a substantial part of the Materials (other than in connection with bona fide search engine indexing or as we may otherwise expressly permit);
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Modify, adapt, translate, or create derivative works based on the Services or any part thereof, except and only if applicable law expressly sanctions that activity notwithstanding this limitation;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Commercially exploit or proffer the Services or the Materials therein to third parties, including any action or “frame” or “mirror” Moozi;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large burden on our technology infrastructure or otherwise make excessive demands on it; or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Attempt to perform any of the acts delineated in this section or assist or permit any person to engage in any of the acts delineated in this section.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>“Refer a friend” bonuses are subject to review and can be denied by Moozi for any reason
                                   </li>
                              </ul>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Engaging in any such prohibited activities will be deemed a violation of these Terms of Service and may lead to immediate suspension or termination of the user’s account without prior notice, at our sole discretion. We reserve the right to pursue any legal remedies or appropriate actions against you if you partake in any of the aforementioned prohibited uses or engage in any unauthorized use of the Services. Any unauthorized use of the Services or our computer systems constitutes a breach of these Terms of Service and may contravene certain international, foreign, and domestic laws.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Reporting Violations
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   If you become aware of any user engaging in activities that contravene these Terms of Service, please contact us at <a class="text-primal-blue underline" href="support@moozi.com" target="_blank" rel="noopener noreferrer">support@moozi.com</a> with as much detail as possible. Include a description of the objectionable activities, the username of the individual involved in suspicious activities, the date and time of identification, the rationale for why we should investigate the activities, and a statement affirming the accuracy of the information provided.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>Law Enforcement Cooperation
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We will fully cooperate with law enforcement authorities or orders from courts of competent jurisdiction, requesting or directing us to disclose the identity or location of any user in violation of these Terms of Service. This cooperation will be conducted in accordance with our privacy policies, law enforcement protocols, and applicable laws or regulations. If your activity results in Moozi receiving a subpoena, discovery request, production order, search warrant, or court order that necessitates expenses, court costs, or legal fees for compliance, you agree to reimburse us for any such expenses, costs, or legal fees upon our request.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IV.</span><span class="underline underline-offset-2">Billing and Payments</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Purchases
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Upon providing valid and up-to-date payment details, you have the option to acquire Gold Coins (“GC”) for gameplay. Occasionally, users who purchase GC may also receive complimentary SC in accordance with our <a class="text-primal-blue underline" href="/sc-coins" target="_blank" rel="noopener noreferrer">SC Promotional Rules</a>. It's important to note that GC hold no monetary value and are solely intended for entertainment purposes. Any unused GC and SC are ineligible for full or partial refunds and may be removed from inactive accounts. The pricing of GC is transparently displayed on Moozi, and purchases must align with the current price listings. SC are not available for direct purchase but can be obtained either in conjunction with the acquisition of GC or through specific free alternative methods outlined in our <a class="text-primal-blue underline" href="sc-coins" target="_blank" rel="noopener noreferrer">SC Promotional Rules</a>. We retain the right to impose additional charges for Moozi access or any associated features or services, as well as to adjust our fee structure at our discretion. Previous offers or discounts become void upon removal from Moozi. User accounts are monitored on a regular basis to ensure that purchased coins are used for gameplay. Accounts with 6 months of idle GC balance while having an active SC balance triggers a review and potential account termination.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You agree to promptly settle any fees, taxes, charges, purchases, or upgrades linked to your account in accordance with our billing policy or that of our designated third-party billing agent. It's understood that charges on your banking statement may reflect our company name, one of our brand names, or the name of our third-party billing agent. You acknowledge full responsibility for any fees or penalties tied to your account. Accounts will be deemed delinquent if not settled in full by the stipulated due date. Additional terms may apply as per your card issuer agreement regarding your rights and responsibilities as a cardholder. Any outstanding amounts must be settled immediately upon cancellation or termination of your account.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Prizes
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Upon winning Prizes as per our <a class="text-primal-blue underline" href="/sc-coins" target="_blank" rel="noopener noreferrer">SC Promotional Rules</a>, we will proceed to issue payment through your designated payment method for any earnings accrued during the preceding pay period, deducting any processing fees associated with your payment choice or related to your account, provided you meet the minimum payment threshold. It is your responsibility to ensure accurate bank account or payment method details for Prize disbursement. Ownership or control of the account associated with your payment method is a prerequisite, and you agree to furnish any requested information to verify such ownership or control. Redemption is only permissible upon reaching the minimum payment threshold. We reserve the right to offset any liabilities, damages, costs, or expenses incurred due to your breach of these Terms of Service against any funds owed to you under these terms. Additionally, we retain the authority to adjust or deduct transferred funds for various reasons, including customer refunds, fraud detection, policy violations, and technical discrepancies.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   All Services, including any SC prizes and winners, are subject to verification by Moozi whose decisions are final and binding in all matters.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>Taxes
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You are liable for the payment of all applicable taxes, and we bear no responsibility for federal income tax withholding, unemployment contributions, workers' compensation, Medicare/Medicaid, or any other employment-related benefits. Upon request, you must furnish us with a Social Security Number or Taxpayer Identification Number to facilitate the issuance of an IRS Form 1099, if mandated by law. Failure to provide such information may lead to termination of these Terms of Service.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">d)</span>Third-Party Payment Processing
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   To facilitate payments associated with Moozi, we engage various third-party payment processors and gateways. We retain the discretion to contract with additional third-party payment processors and gateways as deemed necessary. These third parties may impose their own terms and conditions governing payment processing, which you are obligated to adhere to. Any violation of these terms is solely your responsibility, and we disclaim any liability associated with such violations.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">e)</span>Changing Your Billing Information
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   It is your responsibility to promptly notify our third-party billing agent of any changes, including address updates or alterations to the credit card linked to billing or payment through Moozi, if applicable. You bear responsibility for any credit card chargebacks, dishonored checks, or associated fees incurred by Moozi, as well as any additional charges or penalties imposed by our third-party billing agent.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">f)</span>Chargebacks
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Should you initiate a chargeback for a purchase made on Moozi, it may result in the termination of your account. We require that you first contact us to address any issues before resorting to a chargeback. Additionally, we reserve the right to withhold any Prizes earned from SC associated with GC purchases that are subject to a chargeback.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">g)</span>Changes to Our Billing Methods
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We retain the right to modify our fees and billing methods at any time, including the introduction of administrative or supplementary charges for any feature, with or without prior notification.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">h)</span>Refunds
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You acknowledge and accept our standard policy that all purchases made on Moozi are final and nonrefundable. We maintain the sole discretion to handle refund requests. It is important to note that refunds will not be granted in cases where a chargeback is initiated by the user.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">i)</span>Billing Discrepancies
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   If you suspect any incorrect charges associated with your account, promptly inform our third-party billing agent. Failure to do so within thirty (30) days of the initial appearance of the billing error on any account statement will signify your acceptance of the fee in question, including the resolution of any inquiries raised by your banking institution or its representatives and you absolve us of any liability and relinquish any claims of loss arising from such oversights. These provisions serve as an adjunct to and do not supersede, any terms mandated by third-party billing entities with whom we engage for billing services. You are obligated to review and adhere to the terms stipulated by such entities in addition to those outlined in these Terms of Service.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">j)</span>Unauthorized Credit Card Use
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We maintain a stringent stance against credit card fraud. Should it come to our attention that you have utilized a stolen or fraudulently obtained credit card, we will promptly notify the appropriate law enforcement agencies and terminate your account immediately.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">V.</span><span class="underline underline-offset-2">Pay by Bank Program</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Participation and Termination
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Upon enrolling in the Pay by Bank Program (“Program”), you acknowledge and agree to abide by the terms and conditions outlined in this section (“Agreement”). Your participation in the Program is also governed by our updated Terms of Service and our Privacy Policy. We reserve the right to cancel your participation in the Program for any reason and at any time without notifying you. Any termination of this Agreement, whether initiated by you or us, will not affect any of your or our rights and obligations that have arisen before the effective date of termination of this Agreement. You will be responsible for all the Program transactions completed with your program account, even if we allow a transaction to be completed with your program account after this Agreement has been terminated. We may change any term or condition of this Agreement in our sole discretion and from time to time.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Participation in the Program allows you to link a demand deposit account at an eligible U.S bank or other financial institution (“Bank”) to your account on our website. The demand deposit account that is linked for such purchases is your bank account. In summary, this service allows you to initiate a transfer of funds directly from your bank account to fund and receive payouts from a digital wallet or other account with us.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Eligibility
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You are eligible to register for the Program if you are 18 years of age or older and you have an eligible bank account. The Agreement is in addition to any agreement you may have with your Bank. Benefits and protections for transactions made using the program account may vary from those available through debit cards issued by your Bank. We reserve the right, in our sole discretion, to impose limits on the amount of funds sent through this service, on a per transaction or cumulative basis. There may also be limits on the frequency and number of transactions that may be made by Pay by Bank.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>Registration
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   To register for the Program, you must complete the Program Registration process. We reserve the right to deny your Program Registration, to reject your payment under the Program, and to terminate your ability to participate in the Program for any reason and in our sole discretion. You may terminate your participation in the Program by deleting the Pay by Bank payment tender from your account. You may also terminate your participation in the Program by calling your Bank to revoke authorization.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">d)</span>Information Accuracy; Credit Verification
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You must provide us with information about you and your bank account to register in the Program. We may use other information that you have provided us in the past to complete your registration. You agree to promptly notify us of any changes in any information you provide to us. You acknowledge and agree that any information you provide to us is complete, true, and accurate. You authorize us to share your information to request and obtain, from time to time, consumer reports from consumer reporting agencies and other information about you from third parties (including, without limitation, information concerning your bank account) that we believe is helpful to determine your eligibility to participate in the Program, to complete requested transactions, or for any other legitimate business purpose.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">e)</span>Availability of Funds
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You agree to have sufficient funds in your bank account to satisfy all transactions and other obligations incurred with your payment by the program account (including any cash back received and charges provided for in this Agreement). Payments made with the program account that exceed the balance in your bank account may result in overdrafts and associated fees which you are responsible for, regardless of whether you have opted to allow overdrafts with respect to debit cards issued by your Bank.
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">f)</span>Authorization
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   This authorization applies to your Pay by Bank payment initiation that allows you to initiate a transfer of funds directly from your tied bank account to us. By selecting the Pay by Bank payment tender, you authorize us to electronically debit your bank account up to the total amount of your purchase, including applicable taxes and fees. You also authorize us to further debit or credit your bank account to correct any errors, make any necessary adjustments to your payment, or issue a refund back to your bank account. Due to the speed of electronic payment processing, after you authorize an individual payment, the payment cannot be canceled.
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">g)</span>Payment Returns and Return Fee Disclosure
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   If your bank returns any payment using your bank account unpaid, we may retry the payment. You authorize us to collect a Returned Payment Fee as an electronic fund transfer or draft if permitted by law. The fee is determined based on your state. The following link displays the state-by-state collection fee and cost table for returned payments <a href="https://merchants.fiserv.com/en-us/customer-center/merchants/telecheck-returned-check-fees/" class="text-primal-blue" target="_blank" rel="noopener noreferrer">https://merchants.fiserv.com/en-us/customer-center/merchants/telecheck-returned-check-fees/</a>. Your bank may also charge you an overdraft or other fees for payment failure.
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">h)</span>Account Management
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   It is your responsibility for safeguarding your user identification, password, and any other credentials used to access these services on our website. You are responsible for any activity occurring in your account (including, without limitation, any activity by additional users of your account). If you become aware of any unauthorized access to your account, please notify us and your bank immediately.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You must keep the email address, contact information, and payment details associated with your account current and accurate.
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">i)</span>Data Use
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We will disclose information to third parties about you as part of your registration in the Program, or the transactions you make using your program account and as otherwise permitted in our Privacy Policy. Our service provider, TeleCheck Services, LLC, may also retain, use, combine, disclose, and share your information to facilitate your transactions (including collection of debt related thereto), to conduct its business and provide services consistent with its legal obligations as a consumer reporting agency, for analytics, and for fraud prevention.
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">j)</span>Contacting you by telephone, including wireless phone and emails.
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You agree that in order to service your program account, or collect any amounts that you may owe, we or any of our service providers and/or their agents may contact you by any telephone number or email address associated with your program account, including any wireless number, which may result in charges to you. This may be a number or email that you provided, or a number obtained from other sources. We or any of our service providers may also contact or communicate with you by sending text messages, using any phone number you provided to us. Methods of contact may include using pre-recorded/artificial voice messages and/or use of an automatic dialing device, as applicable. You agree that the email address you provide to us is secure and confidential and that we may use it to communicate to you concerning any matter, including communications regarding unpaid debt items that we or our service providers may be attempting to collect.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   For questions about the Program, please contact us via customer support chat on our website.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   For questions about unauthorized transactions, please contact your Bank.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   For questions about specific Program transactions, please contact TeleCheck Services, LLC by telephone at 888-905-3388. TeleCheck Services, LLC is our third-party service provider that handles the Program payment processing.
                              </p>
                              <br />

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VI.</span><span class="underline underline-offset-2">Dispute Resolution and Damages</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Arbitration
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   It is hereby expressly agreed that any disputes arising under these Terms of Service must always be resolved in Arbitration. In the event of a disagreement with Moozi regarding the Terms of Service, both parties must engage in sincere discussions to resolve the dispute amicably. If direct negotiations fail to resolve the matter, either party must refer the issue to binding, confidential arbitration, following the prevailing Commercial Arbitration Rules of the American Arbitration Association. Arbitral claims encompass contract and tort claims of all kinds, as well as those based on any federal, state, or local law, statute, or regulation, excluding claims related to intellectual property infringement, injunctions, attachment, garnishment, and equitable relief. The arbitration proceedings will take place in Delaware, overseen by a sole arbitrator well-versed in Internet and e-commerce disputes, willing to pledge impartiality. The arbitrator is not empowered to award punitive or exemplary damages, certify a class, add parties, deviate from the terms of this Agreement, and must adhere to governing laws. Within thirty (30) days of concluding the arbitration, the arbitrator will issue a written opinion detailing all material facts and the rationale behind the decision. The arbitration award is enforceable in any jurisdiction, under relevant laws. No waiver of the right to arbitration is valid unless expressly provided in writing by the waiving party. No actions, including litigation filings, shall be construed as a waiver or abandonment of the arbitration right.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Class Action Waiver
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You hereby waive any right to initiate or participate in any class action or collective proceeding, as well as any right to trial by jury.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>Injunctive Relief
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You acknowledge that remedies available at law may be inadequate in the event of a breach of these Terms of Service and agree that we may seek injunctive relief in addition to any other remedies available at law or in equity.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">d)</span>Reimbursement of Fees
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   In the event that we are required to engage legal or other services to collect damages from you or to pursue injunctive relief against you, you agree to reimburse us for all associated fees, including but not limited to those of attorneys, investigators, and collections agents. You understand that even nominal damages may incur extensive legal fees and costs, which you agree to pay.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VII.</span><span class="underline underline-offset-2">Disclaimers</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Disclaimer of Warranties
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We offer access to Moozi and the use of its Services "as is" and "with all faults." We do not warrant that Moozi or the Services will meet your specific needs or requirements. All warranties—express, statutory, or implied—are hereby disclaimed, including but not limited to warranties of merchantability, fitness for a particular purpose, workmanlike effort, quality, suitability, truthfulness, usefulness, performance, accuracy, completeness, reliability, security, title, exclusivity, quiet enjoyment, and non-infringement. We also do not warrant that your access to Moozi or use of the Services will be uninterrupted, timely, secure, or error-free, or that loss of materials will not occur. We reserve the right to change any information on Moozi at any time or to remove any or all Materials without prior notice. There is no commitment from us to update the Materials, and we make no warranty regarding any goods or services purchased or obtained through Moozi or any transactions entered into through Moozi. These disclaimers extend to the fullest extent permitted by applicable law, and there are no warranties beyond what is explicitly stated in these Terms of Service.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Use at Your Own Risk
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You expressly acknowledge and agree that accessing Moozi and using its Services is solely at your own risk. We cannot and do not guarantee or warrant that Moozi or the Services will be free from viruses, malware, worms, Trojan horses, or any other code that may have contaminating or destructive properties. We do not assume any responsibility or risk for your access to or use of the Internet, Moozi, or its Services. Furthermore, any Materials downloaded or obtained through Moozi are done at your own discretion and risk, and you bear sole responsibility for any damage to your computer system or data loss due to such activities.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">c)</span>External References
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moozi may include references to external websites or resources that are owned and managed by our users or third parties. You acknowledge and accept that we have limited control over these external references, and we do not endorse, guarantee, or assume responsibility for the goods or services offered by our users or on third-party websites. We are not involved in monitoring transactions between you and other providers of products or services. Just as with any purchase made through various mediums, it is advisable to exercise caution and use your best judgment. By using third-party links on Moozi, you agree to release us from any damages or liability that may arise, including any issues related to the quality or delivery of products or services. Additionally, we are not liable for the use of confidential or private information by third parties. Your interactions with third-party links and the services or products they offer are subject to the policies of those third parties, not our Terms of Service or other policies. We reserve the right to downgrade or remove any link at our discretion.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">d)</span>Compliance with Legal Requirements
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Accessing Moozi and using its Services in violation of any law is strictly prohibited. If we discover that you have engaged in or intend to engage in activities that violate any law, your access to Moozi and its Services will be immediately terminated. We disclaim any liability for damages resulting from your or any user's unlawful activities. You agree to indemnify and hold us harmless from any liability arising from your unlawful actions, and you agree to defend us against any claims made by third parties harmed by your illegal activities or claims that we are obligated to defend.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VIII.</span><span class="underline underline-offset-2">Indemnification</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You commit to indemnify and absolve Moozi., along with its officers, directors, shareholders, employees, independent contractors, and agents, from any and all claims, actions, losses, liabilities, expenses, costs, or demands. This includes, but is not limited to, legal and accounting fees, arising directly, indirectly, or consequentially from your actions or those of another person under your authority. This encompasses actions against governmental agencies, the use, misuse, or inability to use Moozi or the Materials, and any breach of these Terms of Service by you or someone under your authority. We will promptly notify you via electronic mail of any such claim or suit, and we may, at your expense, fully cooperate in the defense of such claims or suits. While we reserve the right to engage in the defense of such claims or suits at our own expense and with our chosen legal representation, we are not obliged to do so.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IX.</span><span class="underline underline-offset-2">Limitation of Liability</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You acknowledge that we shall not be held liable to you for user-generated Materials or the offensive or unlawful conduct of any individual. You understand that the risk of harm or damage resulting from this solely rests with you, and you expressly release us from any liability arising from user-generated Materials or the conduct of any individual. You hereby discharge, absolve, and release us, our parent company, agents, employees, officers, directors, shareholders, attorneys, and affiliates from any and all allegations, counts, charges, debts, causes of action, and claims relating to the use of, or activities related to the use of Moozi, including claims related to negligence, gross negligence, reckless conduct, intentional infliction of emotional distress, intentional interference with contract or advantageous business relationship, defamation, privacy, publicity, intellectual property infringement, misrepresentation, infectious disease, illegal gambling, financial loss not attributable to Moozi's fault, missed meetings, unmet expectations, false identities, fraudulent acts by others, invasion of privacy, release of personal information, failed transactions, purchases or functionality of Moozi, unavailability of Moozi, its functions, and any other technical failure resulting in Moozi's inaccessibility, or any claim based on vicarious liability for torts committed by individuals met on or through Moozi, including fraud, theft or misuse of personal information, assault, battery, stalking, harassment, cyber-bullying, rape, theft, cheating, perjury, manslaughter, or murder.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The aforementioned list is illustrative and not exhaustive of the types or categories of claims released by you. This release is intended by the parties to be broadly interpreted in favor of Moozi, and therefore any ambiguity shall be construed in a manner providing the release of the broadest claims. The parties acknowledge the legally binding nature of this provision and the rights relinquished in connection therewith.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We explicitly deny any liability or responsibility to you for the below:
                              </p>
                              <br>
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any loss or damage incurred due to the Materials, including errors, mistakes, or inaccuracies of the Materials, or any Materials that are infringing, obscene, indecent, threatening, offensive, defamatory, invasive of privacy, or illegal.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Personal injury or property damage resulting from your access to and use of Moozi.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Unauthorized access by any third party to your account, transmissions, or data.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Interruption or cessation of transmission to or from Moozi, and any delays or failures experienced in initiating, conducting, or completing any transmissions to or transactions with Moozi.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any bugs, viruses, malware, Trojan horses, or similar items transmitted to or through Moozi by any third party.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Incompatibility between Moozi and your other services, hardware, or software.
                                   </li>
                              </ul>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">X.</span><span class="underline underline-offset-2">Intellectual Property</span>
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">a)</span>Trademarks
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moozi stands as our branded name and trademark, and we actively safeguard our intellectual property rights. Any reference to product and service names of other manufacturers within this platform may constitute trademarks and service marks of their respective entities. Such marks are the sole property of their respective owners and must not be utilized publicly without explicit written consent from said owners or holders of such trademarks and service marks. All marks, logos, domains, and trademarks featured on Moozi are restricted from public use except with our express written authorization. Furthermore, they must not be employed in any manner that could potentially confuse consumers or disparage or discredit Moozi.
                              </p>
                              <br>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <span class="mx-5">b)</span>Copyrights
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   The Materials contained herein represent our proprietary information and valuable intellectual property, and we maintain full right, title, and interest in such materials. Moozi, alongside the Materials and our software, is safeguarded by copyright law. These Materials may not be copied, downloaded, distributed, republished, modified, uploaded, posted, or transmitted in any form without our prior written consent. Any removal or alteration, or attempts thereof, of any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Materials is strictly prohibited. Any modification or utilization of the Materials, except as expressly permitted in these Terms of Service, constitutes a violation of our intellectual property rights.
                              </p>

                              <br><br>
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">XI.</span><span class="underline underline-offset-2">General</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   <span class="mr-2">a)</span> <span class="italic">Entire Agreement</span> – These Terms of Service, along with any other legal notice or agreement published on Moozi by us, constitute the complete agreement between you and us. They override all prior terms, understandings, or agreements between you and us concerning Moozi usage. In any proceedings related to these terms, a printed copy of these Terms of Service and any electronically provided notice will be considered admissible. Such printed version of these Terms of Service holds the same evidentiary weight and is subject to the same conditions as other business documents and records initially produced and maintained in printed form.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">b)</span> <span class="italic">Third Party Providers</span> – You acknowledge and consent that we may engage certain third-party service providers to deliver the Services to you. You also acknowledge and agree that you are required to accept and adhere to any user terms, privacy policy, or other policies mandated by such third-party service providers for the use of their services. Should any conflicts arise between these policies and our own, our policies shall take precedence.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">c)</span> <span class="italic">Assignment</span> – We reserve the right to assign any rights or delegate any obligations under these Terms of Service without prior notification to you. However, you are prohibited from assigning, delegating, or sublicensing any of your rights or duties without our prior written consent. Any attempted assignment or delegation in contravention of this provision shall be deemed void.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">d)</span> <span class="italic">Severability</span> – Should any provision of these Terms of Service be deemed invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect, provided the essential terms for each party remain valid, binding, and enforceable.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">e)</span> <span class="italic">Cumulative Remedies</span> – All rights and remedies granted in these Terms of Service are cumulative and non-exclusive. The exercise of any right or remedy by a party shall not preclude the exercise of any other rights or the pursuit of any other remedies available under law, equity, statute, any other agreement between the parties, or otherwise.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">f)</span> <span class="italic">Successors and Assigns</span> – These Terms of Service are binding upon and benefit the parties and their respective successors and assigns. However, this section does not expressly address whether a party may transfer its rights or delegate its obligations under these Terms of Service, whether directly or indirectly.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">g)</span> <span class="italic">Force Majeure</span> – We shall not be held liable for any failure to perform obligations due to unforeseen circumstances or events beyond our reasonable control, including but not limited to: Acts of God such as fires, floods, earthquakes, hurricanes, or other natural calamities; outbreaks of epidemics or pandemics; acts of war, riot, arson, embargoes, actions by civil or military authorities, or acts of terrorism; disruptions in telecommunications or information services infrastructure; hacking, spam, data breaches, malware, or any failures of computers, servers, networks, or software that continue to impede our performance; and unlawful actions committed by our employees, agents, or contractors.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">h)</span> <span class="italic">Notices</span> – Any notice that we are required to give under these Terms of Service may be provided via email to the functioning email address of the party to be notified, through a general posting on Moozi, or via personal delivery through a commercial carrier. Customers can provide notices to us by contacting us at <a class="text-primal-blue underline" href="support@moozi.com" target="_blank" rel="noopener noreferrer">support@moozi.com</a> unless otherwise specified in these Terms of Service. Both parties reserve the right to change the address, email address, or recipient for notices by giving written notice to the other party as per the terms herein. Notices are considered effective upon delivery. Notices sent via overnight carrier are deemed delivered on the subsequent business day after mailing. Notices sent via any other method are deemed given upon receipt. If a notice, properly addressed, is refused, unclaimed, or undeliverable due to an act or omission of the intended recipient, it is considered effective from the date it was refused or deemed undeliverable by the postal authorities, messenger, email server, or overnight delivery service.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">i)</span> <span class="italic">Communications are Non-Private</span> – We do not offer any means for sending or receiving private or confidential electronic communications. Any messages sent to us are considered accessible to the general public. It is hereby acknowledged that all messages inputted into Moozi may be accessed by the agents and operators of Moozi, irrespective of whether they are the intended recipients.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">j)</span> <span class="italic">Permission to Send Emails</span> – You hereby grant us authorization to send you emails containing notices, advertisements, and other communications. This includes communications related to social casinos and associated game promotions. This authorization remains valid until you request removal from our email list. You understand and agree that any unsolicited email correspondence from us or our affiliates does not constitute spam as defined by law.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">k)</span> <span class="italic">Consideration</span> – Your access and use of Moozi, the Services, and the Materials are permitted in exchange for your acceptance of all provisions outlined in these Terms of Service. You acknowledge that such acceptance constitutes adequate consideration, which is received upon your viewing or downloading any part of Moozi.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">l)</span> <span class="italic">Electronic Signatures</span> – You consent to be bound by any affirmation, assent, or agreement transmitted through Moozi. You acknowledge that clicking on an “I agree,” “I consent,” or similar button, checkbox, or entry field with your mouse, keystroke, or other computer device signifies your legally binding agreement or consent. This action is considered the legal equivalent of your handwritten signature.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">m)</span> <span class="italic">English Language</span> – These Terms of Service and our associated website policies are crafted in the English language. By accessing Moozi, you acknowledge your comprehension and agreement to the English language rendition of these Terms of Service as they are presented. We bear no responsibility to you or any third party for any expenses incurred in translating these Terms of Service. Should you opt to translate these Terms of Service, you do so at your own risk, as only the English language version holds legal binding.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">n)</span> <span class="italic">Export Control</span> – You recognize and accept that the software components of the Materials on Moozi may be subject to regulation by governmental entities, which prohibit the export or diversion of software and other goods to certain countries and individuals. It is forbidden to divert such Materials contrary to U.S. or international laws. You shall neither aid nor partake in any such diversion or violation of applicable laws and regulations. You guarantee that you will not authorize or allow any unauthorized party to receive controlled commodities under applicable laws and regulations and that you will adhere to said laws and regulations. You agree that none of the Materials are being or will be obtained for, shipped, transferred, or re-exported, directly or indirectly, to restricted or embargoed countries or their nationals, or be utilized for prohibited activities.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">o)</span> <span class="italic">No Agency Relationship</span> – These Terms of Service do not establish, imply, or give rise to a partnership, employment, joint venture, or formal business entity of any nature. The rights and responsibilities of the parties are confined to those explicitly outlined herein.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">p)</span> <span class="italic">Usages</span> – In these Terms of Service, unless explicitly stated otherwise or the context necessitates otherwise, the following conventions shall apply:
                              </p>
                              <br>
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>References to a statute shall encompass the statute and any subsequent statute, as well as all regulations enacted under or implementing the statute or its successors, as in force at the relevant time.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>When calculating periods from a designated date to a later date, the terms "from" and "commencing on" (and similar phrases) denote "from and including," while the terms "to," "until," and "ending on" (and similar phrases) signify "to but excluding."
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg  flex flex-row">
                                        <span class="mr-5">-</span>References to a governmental or quasi-governmental agency, authority, or instrumentality shall also include any regulatory body that assumes the functions of the agency, authority, or instrumentality.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>"A or B" shall mean "A or B or both." "A, B, or C" shall mean "one or more of A, B, and C." This interpretation extends to longer sequences.
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>"Including" shall mean "including, but not limited to".
                                   </li>
                              </ul>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">q)</span> <span class="italic">No Waiver</span> – No action or waiver undertaken by us shall be construed as a waiver of any subsequent breach of the same provision of these Terms of Service. Should any term, clause, or provision herein be deemed invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not impact the validity or operation of any other term, clause, or provision, and the invalid term, clause, or provision shall be severed from these Terms of Service.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">r)</span> <span class="italic">Headings</span> – All headings are provided solely for ease of reference and shall not alter the meaning, interpretation, or effect of these Terms of Service.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">s)</span> <span class="italic">Other Jurisdictions/Foreign Law</span> – We do not assert that Moozi, the Services, or any of the Materials are suitable or accessible for use in all jurisdictions. You are prohibited from accessing Moozi, the Services, or the Materials from regions where their content may be unlawful or otherwise restricted. Individuals who opt to access Moozi and utilize the Services from such regions do so at their own discretion and are solely responsible for ensuring compliance with all relevant local regulations. Nothing within these Terms of Service should be construed as an acknowledgment that Moozi is subject to the laws of any jurisdiction other than the U.S.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg">
                                   <span class="mr-2">t)</span> <span class="italic">Service Not Available in Some Areas</span> – Your use of Moozi is subject to the laws of the state, province, city, country, or other legal jurisdiction in which you reside or from which you access Moozi.com. MOOZI IS INVALID WHERE PROHIBITED OR RESTRICTED BY LAW. If you establish an account or utilize Moozi while situated in a prohibited jurisdiction, you are violating the laws of that jurisdiction and these Terms of Service and may face suspension or termination of your account without prior notice. By agreeing to these Terms of Service, you acknowledge that Moozi cannot be held accountable if the laws applicable to you limit or prohibit your participation. Moozi does not provide any implicit or explicit assurances regarding your legal entitlement to participate in any Service offered on Moozi, and no individual associated with Moozi, or claiming association, is authorized to provide such assurances. We retain the right to limit access to Moozi in any jurisdiction.
                              </p>
                         </div> -->
                    </div>
               </div>
          </div>
     </div>
</template>
  <script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { ref,computed, onMounted } from "vue";
import { policyStore } from "store/policy.js";
import _ from "underscore";
import LoadingSmall from "components/loading/loadingSmall.vue";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          InertiaHead,
          LoadingSmall,
     },
     setup() {
          const slug = 'terms-of-use';
          
          const { scrollTop } = useScroll();

          const usePolicyStore = policyStore()

          const loading = ref(false);

          const content = computed(() => {
               if (usePolicyStore.policy) {
                    const filter = _.find(usePolicyStore.policy, function (params) {
                         return params['slug'] == slug
                    })

                    return filter ? filter.content : '';
               }

               return ''
          })

          onMounted(async () => {
               loading.value = true
               scrollTop();
               await usePolicyStore.getPolicy();
               loading.value = false
          })

          return {
               content,
               loading
          };
     },
};
</script>


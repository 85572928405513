<template>
     <InertiaHead title="Redeem"></InertiaHead>
     <div class="w-full h-full relative flex justify-center mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-10 md:px-24 lg:px-28 xl:px-32 pb-36 sm:pb-44 md:pb-20 lg:pb-28 xl:pb-32 pt-16 sm:pt-20 lg:pt-28 xl:pt-32">
                    <h1 class="text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">
                         REDEEM
                    </h1>
                    <div class="bg-gradient-promo-detail rounded-xl flex flex-row w-full mt-6 sm:mt-8 md:mt-12 lg:mt-18">
                         <div class="w-6/12 hidden lg:block">
                              <div class="flex flex-col justify-between items-center w-full h-full">
                                   <div class="flex gap-5 items-center w-full mt-16 ml-60">
                                        <span class="icon-redeem text-2xl"></span>
                                        <p class="text-white font-roboto font-extrabold tracking-wider text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg">
                                             Redeem
                                        </p>
                                   </div>
                                   <img
                                        :src="baseUrl.img+'/assets/img/cow-with-logo.png'"
                                        alt="cow"
                                        class=" mt-8 md:mt-16 mb-8"
                                   >
                              </div>
                         </div>
                         <div class="bg-white w-full px-5 md:px-7 lg:px-10 2xl:px-36 py-3 md:py-5 lg:py-10 flex flex-col justify-center items-center rounded-xl lg:rounded-l-none lg:rounded-r-xl">
                              <p class="text-shade-11 font-helveticaLight tracking-wide mt-5 md:mt-8 mb-4 md:mb-8 text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
                                   SCs Coins can only be redeemed for cash after you have used them to play and is subject to a minimum of 100 played SCs Coins.
                              </p>
                              <div class="flex flex-row justify-around items-center w-full">
                                   <div class="flex flex-col justify-center items-center">
                                        <p class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl  lg:text-sm xl:text-base">
                                             Coins balance
                                        </p>
                                        <div class="flex flex-row items-center gap-2 mt-4">
                                             <img
                                                  class="cursor-pointer w-6 h-6 "
                                                  :src="baseUrl.img+'/assets/img/header/SC.svg'"
                                                  alt=""
                                             >
                                             <p class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg">
                                                  {{ money(silver_balance) }} SC
                                             </p>
                                        </div>
                                   </div>
                                   <div class="flex flex-col justify-center items-center">
                                        <p class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl  lg:text-sm xl:text-base">
                                             Available to redeem
                                        </p>
                                        <div class="flex flex-row items-center gap-2 mt-4">
                                             <img
                                                  class="cursor-pointer w-6 h-6 "
                                                  :src="baseUrl.img+'/assets/img/header/SC.svg'"
                                                  alt=""
                                             >
                                             <p class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg">
                                                <!-- {{ silver_balance }}
                                                {{redeemable}}
                                                {{silver_balance <= redeemable}} -->
                                                  {{ money(silver_balance_redeem) }} SC
                                             </p>
                                        </div>
                                   </div>
                              </div>
                              <div class="w-full px-5 py-3 bg-gray-90 rounded-md mt-5">
                                   <p class="text-shade-7 font-helveticaLight tracking-wide text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
                                        Redeem your SCs Coins winnings to your bank account.
                                   </p>
                              </div>
                              <p class="text-shade-11 font-helveticaLight font-bold tracking-wide text-start sm:text-lg md:text-xl lg:text-lg xl:text-lg w-full mb-2 mt-7">
                                   Transfer History
                              </p>
                              <div
                                   v-if="transaction_data && !transaction_data_loading"
                                   class="w-full px-3 py-2 bg-gray-90 rounded-md h-64 mb-3 overflow-auto"
                                   :class="transaction_data.length == 0 ? 'flex flex-row justify-center items-center h-36 mb-3' : ''"
                              >
                                   <div v-if="transaction_data.length == 0">
                                        <p class="text-shade-7 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl  lg:text-sm xl:text-base">
                                             You haven't made any transfers yet.
                                        </p>
                                   </div>
                                   <table
                                        v-else
                                        class="border-separate border-spacing-y-2 table-auto w-full whitespace-nowrap"
                                   >
                                        <thead>
                                             <tr>
                                                  <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                                       ID
                                                  </th>
                                                  <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                                       Date
                                                  </th>
                                                  <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                                       Status
                                                  </th>
                                                  <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                                       Transaction
                                                  </th>
                                                  <th class="text-right custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                                       Amount
                                                  </th>
                                             </tr>
                                        </thead>
                                        <tbody class="text-xs xs:text-sm">
                                             <tr
                                                  v-for="(data, index) in transaction_data"
                                                  :key="index"
                                                  class="custom-flex-items border border-solid border-shade-10"
                                             >
                                                  <td class="pr-5 md:pr-0">{{ data.id }}</td>
                                                  <td class="pr-5 md:pr-0">{{ date6(new Date(data.created_at)) }}</td>
                                                  <td class="pr-5 md:pr-0">
                                                       <div class="flex items-center">
                                                            <div class="status-circle"></div>
                                                            <span class="px-2 rounded-full text-white" :class="data.payment_status == 0
                                                                      ? 'bg-background-4'
                                                                      : data.payment_status == 1
                                                                      ? 'bg-shade-14'
                                                                      : 'bg-background-2'">
                                                                 {{
                                                                      data.payment_status == 0
                                                                      ? "Pending"
                                                                      : data.payment_status == 1
                                                                      ? "Success"
                                                                      : "Failed"
                                                                 }}
                                                            </span>
                                                       </div>
                                                  </td>
                                                  <td class="pr-5 md:pr-0">
                                                       {{
                                                            data.transaction_type == 1
                                                            ? "Purchase"
                                                            : data.transaction_type == 2
                                                            ? "Withdraw"
                                                            : "Bonus"
                                                       }}
                                                  </td>
                                                  <td class="pr-5 md:pr-0">
                                                       <div class="flex item-center justify-end font-bold text-right space-x-5">
                                                            <div class="flex items-center justify-end space-x-0.5">
                                                                 <img
                                                                      :class="
                                                                           data.transaction_type == 3
                                                                           ? 'block h-4'
                                                                           : 'hidden'
                                                                      "
                                                                      :src="baseUrl.img+'/assets/img/coin.png'"
                                                                      alt=""
                                                                 />
                                                                 <div>
                                                                      {{
                                                                           data.transaction_type == 3
                                                                           ? money(JSON.parse(data.conversion).gold_coins)
                                                                           : "$" + money(data.net_amount)
                                                                      }}
                                                                 </div>
                                                            </div>
                                                            <div
                                                                 class="flex items-center justify-end space-x-0.5"
                                                                 :class="data.transaction_type == 3 ? '' : 'hidden'"
                                                            >
                                                                 <img
                                                                      :class="
                                                                           data.transaction_type == 3
                                                                           ? 'block h-4'
                                                                           : 'hidden'
                                                                      "
                                                                      :src="baseUrl.img+'/assets/img/coin-green.png'"
                                                                      alt=""
                                                                 />
                                                                 <div>
                                                                      {{
                                                                           data.transaction_type == 3
                                                                           ? money(JSON.parse(data.conversion).silver_coins)
                                                                           : "$" + money(data.net_amount)
                                                                      }}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </td>
                                             </tr>
                                        </tbody>
                                   </table>
                              </div>
                              <div
                                   v-else-if="transaction_data_loading"
                                   class="w-full px-3 py-2 bg-gray-90 rounded-md flex flex-row justify-center items-center h-36 mb-3 relative"
                              >
                                   <loading-small :isLoading="transaction_data_loading" />
                              </div>
                              <div
                                   v-else
                                   class="w-full px-3 py-2 bg-gray-90 rounded-md flex flex-row justify-center items-center h-36 mb-3"
                              >
                                   <p class="text-shade-7 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
                                        You haven't made any transfers yet.
                                   </p>
                              </div>
                              <div v-if="transaction_data.length != 0" class="flex justify-end w-full">
                                   <p @click="goToTransaction" class="text-background-3 cursor-pointer font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base underline underline-offset-2 mb-2">
                                        See More
                                   </p>
                              </div>
                              <div class="flex justify-start w-full">
                                   <div class="flex flex-row gap-2">
                                        <div
                                             v-if="isActionAccessible('wd_card_button')"
                                             @click="tabs = 'credit'"
                                             class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                                             :class="tabs == 'credit' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
                                        >
                                             Credit Card
                                        </div>
                                        <div
                                             v-if="isActionAccessible('wd_bank_button')"
                                             @click="tabs = 'wallet'"
                                             class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                                             :class="tabs == 'wallet' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
                                        >
                                             Bank Transfer
                                        </div>
                                   </div>
                              </div>
                              <div class="flex items-center mb-2 w-full font-bold mt-5">
                                   {{tabs == 'credit' ? 'Credit Card List' : 'Bank Transfer List'}}
                              </div>
                              <CreditCard
                                   v-if="tabs == 'credit' && isActionAccessible('wd_card_button')"
                                   :form="form"
                                   :onUpdateID="(val, data) => { form.accountId = val; card_selected = data; }"
                                   :clearFormError="clearFormError"
                                   :card_selected="card_selected"
                                   :hideForm="true"
                                   :updateForm="(key, value) => { updateForm(key, value) }"

                              />
                              <Wallet 
                                   v-if="tabs == 'wallet' && isActionAccessible('wd_bank_button')"
                                   :form="form" 
                                   :onUpdateID="
                                        (val) => {
                                             form.accountId = val
                                        }
                                   "
                              ></Wallet>
                              <div class="border border-solid border-shade-6 mt-2 mb-4 w-full">
                              </div>
                              <p class="text-shade-11 font-helveticaLight font-bold tracking-wide text-start sm:text-lg md:text-xl lg:text-lg xl:text-lg w-full mb-2 mt-3">
                                   Redeem amount
                              </p>
                              <p class="text-shade-11 font-helveticaLight tracking-wide text-start w-full font-normal text-sm sm:text-lg md:text-xl  lg:text-sm xl:text-base">
                                   Redeem your SCs Coins winnings to your bank account.
                              </p>
                              <transition enter-from-class="opacity-0" enter-active-class="transition ease-in duration-300"
                                   enter-to-class="opacity-100 scale-100">
                                   <div
                                        v-if="checkActionRestricted()"
                                        class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full">
                                        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                             <path d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                                  stroke="#FFF2B1" stroke-width="2" stroke-linecap="round"
                                                  stroke-linejoin="round" />
                                        </svg>
                                        <p class="text-sm lg:text-base font-medium">
                                             This action is currently restricted pls contact support
                                        </p>
                                   </div>
                              </transition>
                              <input
                                   v-model="form.amount"
                                   type="text"
                                   name="amount"
                                   id="amount"
                                   :readonly="tabs == 'credit' ? !isActionAccessible('wd_card_button') : !isActionAccessible('wd_bank_button')"
                                   class="bg-shade-6 border border-shade-12 font-helveticaLight tracking-wide  text-lg rounded-lg focus:!ring-background-2 focus:!border-background-2 block w-full p-2.5 focus:bg-blue-10 mt-3"
                                   placeholder="Min 100 redeemable SC"
                                   @change="clearFormError('amount')"
                                   :class="
                      [form.errors?.errors?.amount != null
                            ? '!border-primal-red focus:!ring-primal-red focus:!border-primal-red'
                            : '!border-background-3 focus:!ring-background-2 focus:!border-background-2', form.amount != '' ? 'text-shade-2' : 'text-shade-13']
                    "
                              />
                              <p class="w-full font-helveticaLight tracking-wide text-sm text-red-10 mt-1">{{ form.errors?.errors?.amount }}</p>
                              <vue-recaptcha :sitekey="VITE_RECAPTCHA_SITE_KEY" @verify="verifyMethod" @expired="expiredMethod" @render="renderMethod" @error="errorMethod" class="my-5 w-full flex items-center justify-center"></vue-recaptcha>
                              <div class="flex justify-end w-full mt-10 mb-10">
                                   <div
                                        :class="tokenResponse != null && form.amount != '' &&  form.accountId != '' && form.amount >= 100 && parseInt(form.amount) <= parseInt(redeemable) && !checkActionRestricted() ? 'bg-secondary-3 text-primary-1 cursor-pointer' : 'bg-gray-190 text-shade-7 cursor-not-allowed'"
                                        class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        :style="tokenResponse != null && form.amount != '' &&  form.accountId != '' && form.amount >= 100 && parseInt(form.amount) <= parseInt(redeemable) && !checkActionRestricted() ? '' : 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'"
                                        @click="() => {
                                             if(tokenResponse != null && form.amount != '' &&  form.accountId != '' && form.amount >= 100 && parseInt(form.amount) <= parseInt(redeemable) && !checkActionRestricted()) {
                                                  onSubmit()
                                             }
                                        }"
                                   >
                                        Submit
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <WithdrawReminder :tabs="tabs" :onWithdrawCredit="() => withdrawSCCredit()" :onWithdrawWallet="() => withdrawSCWallet()"/>
          <loading-overlay :isLoading="loading" />
     </div>
</template>
<script>
import { reactive, ref, onMounted, computed, watch } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter } from "vue-router";
import { coinsStore } from "store/coins";
import { authStore } from "store/auth.js";
import Form from "vform";
import {
     make_withdraw as makeWithdrawAPI,
     make_withdraw_cc as makeWithdrawccAPI,
     transaction_history as transactionHistoryAPi,
     limitTransaction as limitTransactionAPI
} from "api/transaction.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useToaster } from "composables/use-toaster.js";
import { useScroll } from "composables/use-scroll.js";
import WithdrawReminder from "components/modals/withdrawReminder.vue";
import eventBus from "plugins/event.js";
import LoadingSmall from "components/loading/loadingSmall.vue";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { useActionRestriction } from 'composables/use-restriction.js';
import { useBank } from "composables/use-bank.js";
import { cardStore } from "store/cards.js";
const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { VueRecaptcha } from "vue-recaptcha";

export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          WithdrawReminder,
          LoadingSmall,
          CreditCard,
          Wallet,
          VueRecaptcha
     },
     setup() {
          const { isActionAccessible } = useBank();
          const { isActionRestricted } = useActionRestriction();

          const { date6, money } = useFormatter();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const loading = ref(false);
          const { infoToast, successToast, errorToast } = useToaster();

          const useCoinsStore = coinsStore();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();

          const transaction_data = ref([]);
          const transaction_data_loading = ref(false);
          const transaction_limit = ref(null);

          const card_selected = ref(null);

          const tokenResponse = ref(null);

          const tabs = ref("wallet");

          const redeemable = computed(() => {
               return useCoinsStore.redeemable;
          });

          const bankStatus = computed(() => {
               return useCardStore.bankStatus;
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const isUser = computed(() => {
               return useAuthStore.user
          })

          const form = reactive(
               new Form({
                    amount: "",
                    accountId: "",
                    type: "wallet",
                    billing: {
                         address_1: "",
                         address_2: "",
                         state: "",
                         city: "",
                         zipCode: "",
                    },
                    card_number: "",
                    card_expiration: "",
               })
          );

          const coins = computed(() => {
               return useCoinsStore.coins || null;
          });

          const silver_balance = computed(() => {
               return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
          });

          const silver_balance_redeem = computed(() => {
               return parseFloat(useAuthStore.silver_balance_redeem);
          });

          const filterCoins = (val) => {
               return _.filter(coins.value.coins, function (params) {
                    return params["type"] == val;
               });
          };

          const goTo = (id) => {
               router.push({ path: "/payment/" + id });
          };

          const goToTransaction = () => {
               router.push({ path: "/account/", query: { tab: 'coins', filter: 'withdraw'}});
          };

          const goToWallet = () => {
               router.push({ path: "/account/", query: { tab: 'payment-details', panel: 'wallet'}});
          };

          const onSubmit = async () => {

               if(form.accountId == "") {
                    errorToast("Please Select Wallet!", "top-right");
                    return;
               }

               const temp = tabs.value == 'credit' ? 'worldpay' : 'fiserv';

               const filter = _.filter(transaction_limit.value, function (params) {
                    return params['provider'].toLowerCase() == temp;
               })

               // console.log(parseInt(filter[0]['wd_max_transaction']));
               // console.log(form.amount);
               // console.log(parseInt(form.amount) >= parseInt(filter[0]['wd_max_transaction']));

               if(parseInt(form.amount) >= parseInt(filter[0]['wd_max_transaction'])) {
                    openWidthdrawReminder();
               } else {
                    if(tabs.value == 'credit') {
                         await withdrawSCCredit();
                    } else {
                         await withdrawSCWallet();
                    }
               }
          };

          const withdrawSCWallet = async (isPending = false) => {
               const params = {};
               params['accountId'] = form.accountId
               params['amount'] = form.amount
               params['amount'] = form.amount
               params['g-recaptcha-response'] = tokenResponse.value

               loading.value = true;
               await makeWithdrawAPI(params)
                    .then(async (res) => {
                         if (res) {
                              await withdrawTransaction();
                              await useCoinsStore.balanceRedeemable();

                              closeWidthdrawReminder();
                              if(isPending) {
                                   infoToast(
                                        "Please wait for a moment! you will receive your money in few moments",
                                        "top-right"
                                   );
                              } else {
                                   successToast(
                                        "Redeem Successful",
                                        "top-right"
                                   );
                              }
                              delete form.errors.errors['amount'];
                              form.amount = '';
                              loading.value = false;
                         }
                    })
                    .catch(() => {
                         closeWidthdrawReminder();
                         loading.value = false;
                         errorToast("Redeem Unsuccessful", "top-right");
                    });
          }

          const withdrawSCCredit = async (isPending = false) => {
               loading.value = true;

               setTimeout(async () => {

                    // const tempState = form.billing.state;
                    // const filterState = _.filter(state.value, function (params) {
                    //      return params["province_name"] == tempState;
                    // })
                    const params = {
                         amount: form.amount,
                         card_id: form.accountId,
                         "g-recaptcha-response": tokenResponse.value
                         // billing: {
                         //      address_1: form.billing.address_1,
                         //      state: filterState[0]["province_name_abbr"],
                         //      city: form.billing.city,
                         //      zipcode: form.billing.zipCode,
                         // },
                         // card_number: form.card_number,
                         // card_type: card_selected?.value.paypage?.type,
                         // card_expiration: card_selected?.value.paypage?.card_expiration != null ? card_selected?.value.paypage?.card_expiration : form.card_expiration,
                         // payment_category: 'CARD'
                    };

                    await makeWithdrawccAPI(params)
                         .then(async (res) => {
                              if (res) {
                                   await withdrawTransaction();
                                   await useCoinsStore.balanceRedeemable();
                                   closeWidthdrawReminder();

                                   if(isPending) {
                                        infoToast(
                                             "Please wait for a moment! you will receive your money in few moments",
                                             "top-right"
                                        );
                                   } else {
                                        successToast(
                                             "Redeem Successful",
                                             "top-right"
                                        );
                                   }
                                   delete form.errors.errors['amount'];
                                   form.amount = '';
                                   loading.value = false;
                                   card_selected.value = null
                              }
                         })
                         .catch(() => {
                              closeWidthdrawReminder();
                              loading.value = false;
                              errorToast("Redeem Unsuccessful", "top-right");
                         });
               }, 500);
          }

          const clearFormError = (key) => {
               if(form.amount < 100 || form.amount > parseInt(redeemable.value)) {
                    form.errors.errors[key] = "invalid amount";
               } else {
                    if (typeof form.errors.errors[key] !== "undefined") {
                         delete form.errors.errors[key];
                    }
               }
          };

          const transactionLimit = async () => {
               await limitTransactionAPI()
                    .then((res) => {
                         if (res) {
                              transaction_limit.value = res;
                         }
                    })
                    .catch((err) => {
                         console.error(err);
                    });
          };

          const withdrawTransaction = async () => {
               transaction_data_loading.value = true;
               await transactionHistoryAPi({
                    page: 1,
                    itemsPerPage: 10,
                    transaction_type: "withdraw",
               }).then((data) => {
                    transaction_data.value = data.data;
               });
               transaction_data_loading.value = false;
          };

          const openWidthdrawReminder = () => {
               eventBus.emit("open:withdraw_reminder");
          };

          const closeWidthdrawReminder = () => {
               eventBus.emit("close:withdraw_reminder");
          };

          const updateForm = (key, value) => {
               const split = key.split('.');
               if(split.length == 1) {
                    form[key] = value
               } else if (split.length == 2) {
                    form[split[0]][split[1]] = value
               }
          }

          const checkActionRestricted = () => {
               // WITHDRAW
               const restrictedActionType = 2;

               return isActionRestricted(restrictedActionType)
          }

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {
               // console.log(id);
          };

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          onMounted(async () => {
               transaction_data_loading.value = true
               scrollTop();
               await useCardStore.getBankStatus();
               await useCoinsStore.getCoins();
               await useCoinsStore.balanceRedeemable();
               await transactionLimit();
               await withdrawTransaction();
               await useUtilitiesStore.getState();
          });

          watch(tabs, (newVal) => {
               form.type = newVal;
               form.accountId = "";
               card_selected.value = null
          });

          watch(bankStatus, (newVal) => {

               if (newVal) {
                    tabs.value = isActionAccessible('wd_card_button') ? 'credit' : 'wallet';
               }
          });

          return {
               coins,
               filterCoins,
               date6,
               money,
               goTo,
               silver_balance,
               silver_balance_redeem,
               form,
               onSubmit,
               transaction_data,
               redeemable,
               clearFormError,
               loading,
               goToTransaction,
               goToWallet,
               transaction_data_loading,
               tabs,
               updateForm,
               card_selected,
               baseUrl,
               checkActionRestricted,
               isActionAccessible,
               withdrawSCCredit,
               withdrawSCWallet,
               VITE_RECAPTCHA_SITE_KEY,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               tokenResponse,
               isUser
          };
     },
};
</script>
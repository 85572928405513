import { isLogged } from 'utils/auth.js';
import { authStore } from "store/auth.js";
import { computed } from "vue";

export default async function auth({ to, next, router }) {
  const token = isLogged();
  const useAuthStore = authStore();

  const kycStatus = computed(() => {
    return useAuthStore.kycStatus;
  });

  const kycNotAllowed = computed(() => {
    return useAuthStore.kycNotAllowed;
  });

  // const kycRequiredRoutes = ["redeem", "gold-coin-shop", "payment"];

  await useAuthStore.getUser();

  if (!token) {
    return router.push({ name: 'home' });
  }
  else if (kycNotAllowed.value.includes(to.name) && (kycStatus.value == null || kycStatus.value == false)){
    return router.push({ name: 'home' });
  }
  return next();
}


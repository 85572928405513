<template>

     <InertiaHead title="Promotions"></InertiaHead>
     <div class="w-full h-full relative flex justify-center mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full 3xl:max-w-screen-3xl bg-transparent relative">
               <div class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-10 md:px-24 lg:px-28 xl:px-32 pb-36 sm:pb-44 md:pb-20 lg:pb-28 xl:pb-32">
                    <div
                         v-if="!loading"
                         class="mt-16 md:mt-20 lg:mt-18 w-full"
                    >
                         <div
                              v-if="promoDetail"
                              class="bg-gradient-promo-detail rounded-xl flex flex-col"
                         >
                              <img
                                   :src="(promoDetail['banner'][0] == '/' ? '' : '/')+''+promoDetail['banner']"
                                   alt=""
                                   class="rounded-t-xl"
                              >
                              <Referral v-if="promoDetail['title'] == 'REFER FRIENDS'" :promoDetail="promoDetail" :loading="loading"></Referral>
                              <div v-else class="px-5 md:px-24 lg:px-28 xl:px-32 py-8 md:py-24 lg:py-28 xl:py-32">
                                   <h1 class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">
                                        {{promoDetail['title']}} {{promoDetail['title_highlight']}}
                                   </h1>
                                   <div class="mt-8 md:mt-14 xl:mt-18">
                                        <span
                                             v-html="promoDetail['content']"
                                             class="text-white font-helveticaLight tracking-wide text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                        >
                                        </span>
                                   </div>
                                   <div class="w-full flex justify-center items-center mt-8 md:mt-14 xl:mt-28">
                                        <div
                                             @click="back"
                                             class="rounded-full font-roboto font-extrabold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                                        >
                                             Back to Promotions
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div
                              v-else
                              class="bg-gradient-promo-detail rounded-xl flex flex-col w-full h-max"
                         >
                              <h1 class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl">
                                   No Data Found
                              </h1>
                         </div>
                    </div>
                    <div
                         v-else
                         class="mt-8 md:mt-14 w-full"
                    >
                         <div class="bg-gradient-promo-detail rounded-xl flex justify-center items-center w-full h-96">
                              <pulse-loader
                                   :loading="loading"
                                   color="#570106"
                                   size="20px"
                              ></pulse-loader>
                         </div>

                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { computed, onMounted, ref, watch } from "vue";
// import PromoPagination from "./components/Pages.vue";
import { promoStore } from "store/promotion";
import { useRouter, useRoute } from "vue-router";
import _ from "underscore";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useScroll } from "composables/use-scroll.js";
import Referral from "./components/referral.vue";

export default {
     components: {
          PulseLoader,
          InertiaHead,
          Referral
     },
     setup() {
          const router = useRouter();
          const route = useRoute();
          const { scrollTop } = useScroll();

          const promoDetail = ref(null);
          const loading = ref(true);
          const query = ref(route.path.split("/")[3]);

          const back = () => {
               router.push({
                    path: "/promotion",
               });
          };

          const usePromoStore = promoStore();

          const promoData = computed(() => {
               if (usePromoStore.promo) {
                    const arr = query.value.split("-");
                    if (arr.length != 0) {
                         const id = parseInt(arr[arr.length - 1]);
                         const filter = _.filter(
                              usePromoStore.promo,
                              function (params) {
                                   return params["id"] == id;
                              }
                         );

                         promoDetail.value = filter[0];
                    }
                    loading.value = false;
               } else {
                    setTimeout(() => {
                         loading.value = false;
                    }, 2000);     
               }
               

               return usePromoStore.promo || null;
          });

          watch(promoData, (newVal) => {
               if (newVal) {
                    const arr = query.value.split("-");
                    if (arr.length != 0) {
                         const id = parseInt(arr[arr.length - 1]);
                         const filter = _.filter(newVal, function (params) {
                              return params["id"] == id;
                         });

                         promoDetail.value = filter[0];
                    }
               }
               loading.value = false;
          });

          onMounted(() => {
               scrollTop();
               usePromoStore.getPromotion({published_at: 'promotion'});
          });

          return {
               promoDetail,
               back,
               loading,
          };
     },
};
</script>

<template>

     <InertiaHead title="Unique Access Code"></InertiaHead>
     <div class="w-full h-full relative mt-16 md:mt-22 lg:mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full bg-transparent relative ">
               <div
                    class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pb-16 sm:pb-18 md:pb-20 lg:pb-28 xl:pb-32">
                    <div
                         v-if="selfExclusion == null"
                         class="bg-background-1 rounded-md drop-shadow-2xl mt-18 md:mt-20 xl:mt-28 w-full lg:w-3/4 xl:w-7/12 2xl:w-5/12 md:w-2/3 px-5 md:px-8 xl:px-8 lg:px-8">
                         <div class="mt-4 xl:mt-8 mb-12">
                              <div
                                   class="text-3xl xl:text-4xl text-center xl:text-left  uppercase text-white font-helvetica tracking-wide">
                                   Generate your unique request code </div>
                              <div class="pt-10 font-helveticaLight text-white text-sm xl:text-md">
                                   <div class="font-medium tracking-wide">
                                        Please click the ‘<span class="text-primal-yellow font-bold">Request
                                             Code</span>’ button to get your special one-time code.
                                   </div>
                                   <div class="font-medium tracking-wide">
                                        After that, you'll see a <span class="text-primal-yellow font-bold">10-digit
                                             code</span>. We
                                        suggest
                                        taking a screenshot or writing down this code
                                        because you'll need it for the next step.
                                   </div>
                                   <div class="p-2 bg-background-2 my-10 rounded-md">
                                        <p class="text-sm text-background-4">
                                             Remember, clicking the button multiple times won't give you a new code.
                                             Each
                                             code is
                                             only good
                                             for one request. Only codes created on this specific page are allowed for
                                             the
                                             request
                                             process.
                                        </p>
                                   </div>
                              </div>
                              <div v-if="unique_code != null" class="pt-6">
                                   <div class="blue-box px-0 md:px-2 lg:px-3 xl:px-3 py-4">
                                        <div class="font-helveticaLight text-white font-light text-sm lg:text-md pb-2">
                                             Your unique code :
                                        </div>
                                        <form class="flex flex-row">
                                             <input type="text" name="code" id="code"
                                                  class="w-10/12 lg:w-4/5 xl:w-4/5 mr-0 md:mr-2 lg:mr-2 xl:mr-2 border bg-background-1 font-inter border-white tracking-widest text-sm xl:text-xl lg:text-xl px-2 py-2 text-center rounded-full  block text-white"
                                                  :value="unique_code.code" placeholder="" disabled>
                                             <div class="px-1 flex justify-center ">
                                                  <button @click="getCode(unique_code.code)"
                                                       class="font-bold bg-white border rounded-full text-black px-4 md:px-6 lg:px-6 xl:px-6 md:w-32 lg:w-32 xl:w-32 py-2 md:py-2 lg:py-4 xl:py-4">Copy</button>
                                             </div>
                                        </form>
                                        <div class="font-inter font-light text-white text-sm xl:text-md pt-4">
                                             This unique code is valid for one user. wait a few minutes to request a new
                                             code.
                                        </div>
                                   </div>
                              </div>
                              <div class="pt-4 flex flex-col justify-center items-center ">
                                   <vue-recaptcha :sitekey="VITE_RECAPTCHA_SITE_KEY"
                                        @verify="verifyMethod" @expired="expiredMethod" @render="renderMethod"
                                        @error="errorMethod" class="mb-5" :class="showRecaptcha ? '' : 'hidden'">
                                   </vue-recaptcha>
                                   <!-- :disabled="showRecaptcha && tokenResponse == null" -->
                                   <button @click="openRequest"
                                        class="font-roboto text-black bg-secondary-3 mt-4 px-16 font-bold py-2 text-lg rounded-full tracking-wide"
                                        :class="showRecaptcha ? (tokenResponse == null ? '!bg-gray-190 text-shade-7 cursor-not-allowed' : '') : '!bg-gray-190 text-shade-7 cursor-not-allowed'"
                                        :disabled="showRecaptcha ? (tokenResponse == null ? true : false) : true">Request
                                        Code
                                   </button>
                              </div>

                         </div>
                    </div>
                    <SelfExclusionAlert :selfExclusion="selfExclusion" v-else class="pt-18 sm:pt-20 lg:pt-28 xl:pt-32"/>
               </div>
          </div>

     </div>
     <loading-overlay :isLoading="loading" />
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { getUniqueCode, uniqueCodeStatus } from "api/auth.js";
import { Head as InertiaHead } from "@inertiajs/vue3";
import { ref, computed, onMounted } from "vue";
import { authStore } from "store/auth.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import _ from "underscore";
import { useToaster } from "composables/use-toaster.js";
import { useScroll } from "composables/use-scroll.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";

const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          VueRecaptcha,
          SelfExclusionAlert
     },
     setup() {
          const useAuthStore = authStore();
          const loading = ref(false);
          const { successToast, errorToast } = useToaster();
          const { scrollTop } = useScroll();

          const showRecaptcha = ref(false);
          const tokenResponse = ref(null);
          const unique_code = ref(null);

          const selfExclusion = ref(null);

          const countdown = ref(null);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const getCode = (code) => {
               const codeEl = document.createElement("textarea");
               codeEl.value = code;
               document.body.appendChild(codeEl);
               codeEl.select();
               document.execCommand("copy");
               document.body.removeChild(codeEl);
               successToast("Code is copied successfully!", "top-right");
               event.preventDefault();
          };

          const getUniqueCodeLoad = async () => {
               loading.value = true;
               const data = {
                    "g-recaptcha-response": tokenResponse.value,
               };
               try {
                    await requestCode(showRecaptcha.value ? data : null);
                    await useAuthStore.getUser();
                    setTimeout(() => {
                         showRecaptcha.value = false;
                         tokenResponse.value = null;
                         loading.value = false;
                    }, 1000);
               } catch (error) {
                    loading.value = false;
                    console.error("Error getting unique code:", error);
               }
          };

          const requestCode = async (data) => {
               return new Promise((resolve, reject) => {
                    getUniqueCode(data)
                         .then((response) => {
                              if (!response) {
                                   reject("Failed to load unique code!");
                              }

                              unique_code.value = response;

                              if (response?.countdown) {
                                   countdown.value = 0;
                                   const timer = setInterval(() => {
                                        countdown.value = countdown.value + 1;

                                        if (countdown.value == 600) {
                                             clearInterval(timer);
                                             showRecaptcha.value = true;
                                        }
                                   }, 1000);
                              }
                              resolve();
                         })
                         .catch((error) => {
                              errorToast(
                                   "Error to load unique code!",
                                   "top-right"
                              );
                              reject(error);
                         });
               });
          };

          const codeStatus = async () => {
               return new Promise((resolve, reject) => {
                    uniqueCodeStatus()
                         .then((response) => {
                              if (!response) {
                                   reject("Failed to load unique code!");
                              }

                              if (response?.message == 'No Unique Code Set') {
                                   showRecaptcha.value = response?.status;
                                   // console.log(showRecaptcha.value);
                              } else {
                                   showRecaptcha.value = !response?.status;
                              }

                              if (!showRecaptcha.value) {
                                   const filter = _.filter(
                                        isUser.value.meta,
                                        function (params) {
                                             return (
                                                  params["meta_key"] ==
                                                  "unique_code"
                                             );
                                        }
                                   );

                                   if (filter.length != 0) {
                                        unique_code.value = {
                                             code: filter[filter.length - 1][
                                                  "meta_value"
                                             ],
                                        };
                                   }
                              }

                              if (response?.countdown) {
                                   countdown.value = response?.countdown;
                                   const timer = setInterval(() => {
                                        countdown.value = countdown.value + 1;

                                        if (countdown.value == 600) {
                                             clearInterval(timer);
                                             showRecaptcha.value = true;
                                        }
                                   }, 1000);
                              }
                              resolve();
                         })
                         .catch((error) => {

                              if(error.response.data) {
                                   selfExclusion.value = error.response.data;
                              }
                              reject(error);
                         });
               });
          };

          const openRequest = async () => {
               if (isUser.value == null) {
                    router.push({ name: "login" });
               } else {
                    await getUniqueCodeLoad();
               }
          };

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {
               // console.log(id);
          };

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          onMounted(() => {
               scrollTop();
               codeStatus();
               setTimeout(function() {
                    var script = document.createElement('script');
                    script.src = 'https://www.google.com/recaptcha/enterprise.js';
                    document.body.appendChild(script);
               }, 1000); // Adjust delay time as needed
          });

          return {
               getCode,
               openRequest,
               unique_code,
               loading,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               VITE_RECAPTCHA_SITE_KEY,
               tokenResponse,
               showRecaptcha,
               selfExclusion
          };
     },
};
</script>
